/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Home from './home';

export default function NonTOCPage (props) {
    return (
      <Home crumbs={props.crumbs}>
        <div className="usa-grid bg0" style={{ borderTop: '1px solid #DDD', paddingTop: '2em', paddingBottom: '2em', position: 'relative' }}>
          <div className={`non-toc-page article ${props.articleClass}`}>
            {props.children}
          </div>
        </div>
      </Home>
    );
}
