/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroSlide from './heroSlide';

export default function HeroCarousel({ setSite }) {
    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={3}
          interval={5000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <HeroSlide
                title="Dependent Care FSA"
                img="/assets/img/FINRED-WebsiteImage-Sept2024-H-DCFSA.jpg"
                imgAlt="Service members with children"
                buttonLink="/Benefits/DCFSA"
                buttonText="Check It Out"
                // buttonCentered
              >
                <p>
                Learn how military families can take advantage of tax savings with this account.
                </p>
              </HeroSlide>
            </Slide>


            <Slide index={1}>
              <HeroSlide
                // setSite={setSite}
                title="MLMs: What You Need to Know"
                img="/assets/img/FINRED-WebsiteImage-Sept2024-H-MLMBlog.jpg"
                imgAlt="Woman looking at products on table at home"
                buttonLink="/Trending/Blog/MLM"
                buttonText="Get the Answers"
              >
                <p>
                Thirteen questions to ask before working with a multi-level marketing or direct-sales company.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={2}>
              <HeroSlide
                // setSite={setSite}
                title="To Rent or To Buy?"
                img="/assets/img/FINRED-WebsiteImage-Sept2024-H-HBB.jpg"
                imgAlt="Young couple with real estate agent in living room"
                buttonLink="/Money/HomeBuyingBasics"
                buttonText="Watch Now"
              >
                <p>
                That is the question that service members answered in this new seven-part home buying video series.
                </p>
              </HeroSlide>
            </Slide>

            {/* <Slide index={3}>
              <HeroSlide
                setSite={setSite}
                title="Join Us on LinkedIn!"
                img="/assets/img/FINRED-WebsiteImage-February2024-HomepageHero-LinkedIn.jpg"
                imgAlt="LinkedIn logo"
                buttonLink="http://linkedin.com/company/dodfinred"
                buttonText="Get Connected"
                // buttonCentered
              >
                <p>
                Follow the DoD Office of Financial Readiness for fresh financial tips and news.
                </p>
              </HeroSlide>
            </Slide> */}
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
